import React from 'react'
import { Link } from 'react-router-dom'
import RegisterImage from '../../assets/images/register_bg.jpg'
import RegisterFrom from './register_from'

const RegisterPage = () => {
    return (
        <section id="content_section">
            <div className="register_page">
                <div className="page_width">
                    <div className="register_inner">
                        <div className="register_left"><img src={RegisterImage} alt="" /></div>
                        <div className="register_right">
                            <RegisterFrom />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RegisterPage