import React from 'react'
import BannerImg from '../../../assets/images/banner/banner_img1.jpg'
import AppStore from '../../../assets/images/app_store.png'
import GoogleStore from '../../../assets/images/google_play.png'

const Banner = () => {
    return (
        <>
            <section id="banner_section">
                <div className="banner_block">
                    <div className="page_width">
                        <div className="banner_detail">
                            <div className="banner_title"><span>DOWNLOAD</span> APPS</div>
                            <div className="banner_info">Fixxify helps you to find and hire best skilled professionals near your area</div>
                            <div className="banner_app_button"><a href="javascript:void(0)"><img src={AppStore} alt="" /></a><a href="javascript:void(0)"><img src={GoogleStore} alt="" /></a></div>
                        </div>
                    </div>
                    <div className="banner_image"><img src={BannerImg} alt="" /></div>
                </div>
                <div id="banner_search">
                    <div className="page_width">
                        <div className="banner_search_inner">
                            <div className="banner_search_title">What is your job?</div>
                            <div className="banner_search_div">
                                <input type="text" className="banner_search_input" />
                                <input type="button" className="gerenric_button" defaultValue="Find a Job" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner