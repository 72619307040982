import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import ExpertCard from '../../../global/components/expertCard'
import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApi from '../../../global/hooks/api'
const TopExpert = () => {

    const { data, loading, error, setEndpoint, getData } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_top_experts&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const expertData = data && data.data

    console.log("top expert data", expertData)

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className="expert_section gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">TOP EXPERTS</div>
                    <div className="gerenric_product full_column">
                        <div className="gerenric_slider">
                            <Slider {...settings} >
                                {expertData != null && expertData.length > 0 && expertData.map((item, index) => {
                                    return (
                                        <ExpertCard
                                            key={index}
                                            rating={item?.user_total_rating}
                                            name={`${item?.user_fname} ${item?.user_lname}`}
                                            desigi={item?.urole_name}
                                            id={item?.mem_uid}
                                        />
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopExpert