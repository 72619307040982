import { useState, useEffect } from 'react';
import axios from 'axios';

const useApi = () => {
    const baseUrl = 'http://www.fixxify.nl/demo/api/index.php?action=';
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [endpoint, setEndpoint] = useState('');

    const fetchData = async (method, postData = null) => {
        try {
            const url = `${baseUrl}${endpoint}`;
            const config = {
                headers: {
                    'Authorization': `Basic ${btoa(`fixxify:admin123`)}`
                }
            };

            const response = method === 'get'
                ? await axios.get(url, config)
                : await axios.post(url, postData, config);

            if (response.status >= 400) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            setData(response.data);
        } catch (error) {
            console.error('Error in API request:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getData = async () => {
        setLoading(true);
        setError(null);
        fetchData('get');
    };

    const postData = async (postData) => {
        setLoading(true);
        setError(null);
        fetchData('post', postData);
    };

    useEffect(() => {
        if (endpoint) {
            getData();
        }
    }, [endpoint]);

    return { data, loading, error, setEndpoint, getData, postData };
};

export default useApi;
