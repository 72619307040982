import React, { useEffect, useState } from 'react'
import useApi from '../../global/hooks/api'

import { registerUserForm, getRegisterUserSelector } from '../../redux/slices/registerSlice'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { Link } from 'react-router-dom';


const RegisterFrom = () => {
    const { data, error, loading, getData, setEndpoint } = useApi();
    const dispatch = useDispatch()

    const selLang = useSelector(langSelector);
    const userResponse = useSelector(getRegisterUserSelector);
    const status = useSelector((state) => state.registerUser.status);

    useEffect(() => {
        setEndpoint(`get_user_roles&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const [formData, setFormData] = useState({
        urole_id: '',
        user_name: '',
        user_fname: '',
        user_lname: '',
        user_password: '',
        user_phone: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (status === 'sign_up_success') {
            setFormData(
                {
                    urole_id: '',
                    user_name: '',
                    user_fname: '',
                    user_lname: '',
                    user_password: '',
                    user_phone: '',
                }
            )
        }
    }, [status]);
    const userRole = data?.data && data?.data

    const SaveRegisterForm = () => {
        dispatch(registerUserForm(formData));
    };

    return (

        <div className="gerenric_form">
            <div className="gerenric_heading">Register Now?</div>
            <ul>
                <li>{userResponse != null && userResponse != '' && (
                    <>
                        <p>{userResponse?.message}</p>
                    </>
                )}
                    <p></p>
                </li>
                <li>
                    <select name="urole_id" className='gerenric_input' id=""
                        value={formData.urole_id}
                        onChange={handleInputChange} >
                        <option>Select Role</option>
                        {userRole && userRole.map((item, index) => {
                            return (
                                <option value={item?.urole_id} key={index}> {item?.urole_name} </option>
                            )
                        })}
                    </select>
                </li>
                {formData?.urole_id ?
                    <>
                        <li>
                            <div className="form_row">
                                <div className="col_left">
                                    <input type="text" name='user_fname' className="gerenric_input" placeholder="First Name"
                                        value={formData.user_fname}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col_right">
                                    <input type="text" className="gerenric_input" placeholder="Last Name"
                                        name='user_lname'
                                        value={formData.user_lname}
                                        onChange={handleInputChange}
                                    /></div>
                            </div>
                        </li>
                        <li>
                            <input type="text" className="gerenric_input" placeholder="Phone Number"
                                name='user_phone'
                                value={formData.user_phone}
                                onChange={handleInputChange}
                            />
                        </li>
                        <li>
                            <input type="email" className="gerenric_input" placeholder="Email Address / User Name"
                                name='user_name'
                                value={formData.user_name}
                                onChange={handleInputChange}
                            />

                        </li>
                        <li>
                            <input type="password" className="gerenric_input" placeholder="Password"
                                name='user_password'
                                value={formData.user_password}
                                onChange={handleInputChange}
                            />
                        </li>
                    </>
                    : ''}


                <li><button className="gerenric_button button_full" onClick={SaveRegisterForm} >REGISTER</button></li>
                <li className="txt_align_center">Already have an account? <Link to="/login">Login</Link></li>
            </ul>
        </div>

    )
}

export default RegisterFrom