import React from 'react'

const FindProfessional = () => {
    return (
        <>
            <div className="professional_section gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">FIND PROFESSIONALS FOR EVERY JOB</div>
                    <div className="professional_inner">
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">Extension and renovation</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about extensions and renovation</a></li>
                                        <li><a href="javascript:void(0)">contractors</a></li>
                                        <li><a href="javascript:void(0)">facade specialists</a></li>
                                        <li><a href="javascript:void(0)">masons</a></li>
                                        <li><a href="javascript:void(0)">designers</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">Floors and tiles</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about floors and tiles</a></li>
                                        <li><a href="javascript:void(0)">Tilers</a></li>
                                        <li><a href="javascript:void(0)">Floor specialists</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">Gardening and outdoors</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about gardening and the outdoors</a></li>
                                        <li><a href="javascript:void(0)">Gardeners</a></li>
                                        <li><a href="javascript:void(0)">Pavers</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">To paint</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about painting</a></li>
                                        <li><a href="javascript:void(0)">Wallpaperers</a></li>
                                        <li><a href="javascript:void(0)">Painters</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">Roofs</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about roofs</a></li>
                                        <li><a href="javascript:void(0)">Roofers</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="professional_col">
                            <div className="professional_item">
                                <div className="professional_title">kitchen</div>
                                <div className="professional_list">
                                    <ul>
                                        <li><a href="javascript:void(0)">All information about kitchen</a></li>
                                        <li><a href="javascript:void(0)">Kitchen fitters</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FindProfessional