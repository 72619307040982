import React from 'react'
import AppStore from '../../assets/images/app_store.png'
import GoogleStore from '../../assets/images/google_play.png'

const DownloadCard = () => {
    return (
        <>
            <div className="app_download_section">
                <div className="page_width">
                    <div className="app_download_detail">
                        <div className="app_title">Download Fixxify <br />App Now!</div>
                        <div className="app_button">
                            <a href="javascript:void(0)"><img src={AppStore} alt="" /></a>
                            <a href="javascript:void(0)"><img src={GoogleStore} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadCard