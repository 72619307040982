import React from 'react'
import Banner from './features/banner'
import WorkSpot from './features/workSpot'
import Category from './features/category'
import DownloadCard from '../../global/components/downloadCard'
import PrepareWinter from './features/prepareWinter'
import FindProfessional from './features/findProfessional'
import TopExpert from './features/topExpert'

const HomePage = () => {
    return (
        <>
            <Banner />
            <section id="content_section">
                <WorkSpot />
                <Category />
                <DownloadCard />
                <PrepareWinter />
                <FindProfessional />
                <TopExpert />
            </section>
        </>
    )
}

export default HomePage