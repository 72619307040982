import React from 'react'
import SpotImg1 from '../../../assets/images/work_image1.svg'
import SpotImg2 from '../../../assets/images/work_image2.svg'
import SpotImg3 from '../../../assets/images/work_image3.svg'

const WorkSpot = () => {
    return (
        <>
            <div className="how_work_section gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">How does Werkspot work?</div>
                    <div className="gerenric_product">
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={SpotImg1} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Post your job</div>
                                    <p>Post your job in a few simple steps and invite interesting professionals to respond.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={SpotImg2} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Professionals respond</div>
                                    <p>Interested professionals usually respond within 24 hours of posting your job.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={SpotImg3} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Select and start a conversation</div>
                                    <p>Compare profiles and add your favorite professionals to your selection to discuss your job and request a quote.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkSpot