import React from 'react'
import AppStore from '../../assets/images/app_store.png'
import GoogleStore from '../../assets/images/google_play.png'
import { Link } from 'react-router-dom'

const Footer = ({ webData }) => {
    return (
        <>
            <footer id="footer_section">
                <div className="page_width">
                    <div className="footer_inner">
                        <div className="footer_col">
                            <div className="footer_title">{webData?.cnt_heading}</div>
                            <p>{webData?.cnt_details}</p>
                        </div>
                        <div className="footer_col">
                            <div className="footer_title">Links</div>
                            <ul>
                                <li><Link to="/">Home </Link></li>
                                <li><Link to="/category">Find a Job</Link></li>
                                <li><Link to="/how-it-work">How it work</Link></li>
                                <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                                <li><Link to="/terms_and_conditions">Terms and condition</Link></li>
                            </ul>
                        </div>
                        <div className="footer_col">
                            <div className="footer_title">Copyright</div>
                            <ul>
                                <li>© Copyright 2023 Fixxify. All rights reserved</li>
                                <li><a href="javascript:void(0)"><img src={AppStore} alt="" /></a> <a href="javascript:void(0)"><img src={GoogleStore} alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer