import React from 'react'
import Image1 from '../../assets/images/work_image1.svg'
import Image2 from '../../assets/images/receive_icon.png'
import Image3 from '../../assets/images/professional_meet_icon.png'
import Image4 from '../../assets/images/job_icon.png'
import Image5 from '../../assets/images/payment_icon.png'
import Image6 from '../../assets/images/rate_icon.png'

const HowItWork = () => {
    return (
        <section id="content_section">
            <div className="how_work_page gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">How does Werkspot work?</div>
                    <div className="gerenric_product">
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image1} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Post your job</div>
                                    <p>Post your job in a few simple steps and invite interesting professionals to respond.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image2} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Receive proposals</div>
                                    <p>After you have placed your job, we will forward your request to qualified professionals. Please wait patiently until you receive proposals by email.									</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image3} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Professional who Meets</div>
                                    <p>Once you have found a professional who meets your expectations, you can contact him by email or telephone.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image4} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Do the job</div>
                                    <p>Post your job in a few simple steps and invite interesting professionals to respond.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image5} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Payment Method</div>
                                    <p>Interested professionals usually respond within 24 hours of posting your job.</p>
                                </div>
                            </div>
                        </div>
                        <div className="pd_col">
                            <div className="pd_item">
                                <div className="pd_icon"><img src={Image6} alt="" /></div>
                                <div className="pd_detail">
                                    <div className="pd_title">Rate your experience</div>
                                    <p>After completing your job, you can share your experience with the professional. Providing reviews also helps other consumers to select a professional.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork