import React from 'react'
import ExpertImg from '../../assets/images/exerts_image1.jpg'
import { FaStar, FaRegStar } from "react-icons/fa"
import { Rating } from 'react-simple-star-rating'
import { Link } from 'react-router-dom'
const ExpertCard = ({ card_img, name, desigi, rating, id }) => {
    return (
        <>
            <div className="pd_col">
                <Link to={`/expert_details/${id}`} >
                    <div className="pd_item">
                        <div className="pd_image"><img src={card_img ? card_img : ExpertImg} alt="" /></div>
                        <div className="pd_detail">
                            <div className="pd_title">{name ? name : 'Name'}</div>
                            <div className="designation_title">{desigi ? desigi : 'Plumber'}</div>
                            <div className="rating_star">
                                <Rating initialValue={rating ? rating : 0} readonly />
                            </div>
                        </div>
                    </div>
                </Link>

            </div>
        </>
    )
}

export default ExpertCard