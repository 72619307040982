import React, { useState, useEffect } from 'react'
import Logo from '../../assets/images/logo_trans.png'
import ENicon from '../../assets/images/en_icon.svg'
import DUicon from '../../assets/images/du_icon.png'
import UserIcon from '../../assets/icons/user.png'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage, langSelector } from "../../redux/slices/langSlice";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { logout } from '../../redux/slices/authSlice';

const Header = ({ webData }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isHeaderSticky, setHeaderSticky] = useState(false);
    const dispatch = useDispatch();
    const selLang = useSelector(langSelector);
    const auth = useSelector((state) => state.auth);
    useEffect(() => {
        const handleScroll = () => {
            const windowTop = window.scrollY || document.documentElement.scrollTop;

            if (windowTop > 10) {
                setHeaderSticky(true);
            } else {
                setHeaderSticky(false);
            }
        };

        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Detach the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleMenuClick = () => {
        setMenuOpen(!isMenuOpen);
    };
    return (
        <>
            <header id="header_section" className={isHeaderSticky ? 'headersticky' : ''} >
                <div className="page_width">
                    <div className="headerinner">
                        <div className={`${isMenuOpen ? 'closeMenu' : 'menu_icon'}`} onClick={handleMenuClick} >
                            <div id="menu-icon" >
                                <div className="Mbarline" />
                                <div className="Mbarline" />
                                <div className="Mbarline" />
                            </div>
                        </div>
                        <div id="logo" onClick={handleScrollToTop} ><Link to="/"><img src={Logo} alt="logo" /></Link></div>
                        <nav id="navigation_section" className={` ${isMenuOpen ? 'showMenu' : ''}`} >
                            <ul>
                                <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/">Home</Link></li>
                                <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/category">Find a Job</Link></li>
                                <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/experts">Find a Expert</Link></li>
                                <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/how-it-work">How it Work?</Link></li>
                                {(auth?.isAuthenticated == true && auth?.user?.urole_id == 4) ?
                                    <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/post_job">Post Job</Link></li>
                                    :
                                    ''}

                                {auth?.isAuthenticated ?
                                    ''
                                    :
                                    <li onClick={() => { handleMenuClick(); handleScrollToTop() }}>
                                        <Link to="/login">Login</Link>
                                    </li>
                                }
                                {auth?.isAuthenticated ?
                                    <li onClick={() => { handleMenuClick(); handleScrollToTop() }} className='user_profile_header' >
                                        <img src={UserIcon} width={35} alt="" />
                                        <div className="user_menu">
                                            <li onClick={() => { handleMenuClick(); handleScrollToTop() }} > <Link to="/">Dashboard</Link> </li>
                                            <li onClick={() => { handleMenuClick(); handleScrollToTop() }}> <Link to='/'> Update Profile </Link> </li>
                                            <li onClick={() => { handleMenuClick(); handleScrollToTop() }}> <Link to="/" > Change Password </Link> </li>
                                            <li onClick={() => { handleMenuClick(); handleScrollToTop(); dispatch(logout()) }} className='logout_section' ><RiLogoutCircleRLine /> <span>Logout</span> </li>
                                        </div>
                                    </li>
                                    :
                                    <li onClick={() => { handleMenuClick(); handleScrollToTop() }}><Link to="/register"><div className="gerenric_button">REGISTER</div></Link></li>
                                }

                            </ul>
                        </nav>
                        <div className="header_language">
                            <div className="language_select"><a to="javascript:void(0)"><img src={selLang == 'en' ? ENicon : DUicon} alt="" /> {selLang == 'en' ? 'English' : 'Dutch'} <i className="fa fa-caret-down" aria-hidden="true" /></a></div>
                            <ul>
                                <li><a onClick={() => dispatch(changeLanguage('en'))} ><img src={ENicon} alt="" /> English</a></li>
                                <li><a onClick={() => dispatch(changeLanguage('de'))}><img src={DUicon} alt="" /> Dutch</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header