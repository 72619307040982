import React, { useEffect, useState } from 'react'
import useApi from '../../global/hooks/api'

import { postJobForm, getPostJobSelector, uploadpostJobImage } from '../../redux/slices/registerSlice'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';


const PostJob = () => {
    const { data: relation_data, getData, setEndpoint } = useApi();
    const { data: cat_data, error, loading, getData: CatData, setEndpoint: CatEnd } = useApi();
    const dispatch = useDispatch()
    const selLang = useSelector(langSelector);
    const jobResponse = useSelector(getPostJobSelector);
    const status = useSelector((state) => state.registerUser.status);
    const [addDetails, setAddDetails] = useState('');
    const auth = useSelector((state) => state.auth);
    const [fileType, setFileType] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

    useEffect(() => {
        setEndpoint(`get_lov_relations&lang_id=${selLang == 'en' ? 1 : 2}`);
        CatEnd(`get_categories&lang_id=${selLang == 'en' ? 1 : 2}`);
        CatData();
        getData();
    }, [selLang]);

    const relationData = relation_data?.data
    const categorData = cat_data?.data

    const [formData, setFormData] = useState({
        ad_id: 0,
        ad_title: '',
        cat_id: '',
        ad_date: '',
        ad_expiry: '',
        ad_lng: '',
        ad_lat: '',
        ad_location: '',
        relation_id: '',
        ad_age_of_asset: '',
        ad_details: addDetails,
        mem_uid: auth?.mem_uid,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (status === 'post_success') {
            setFormData(
                {
                    ad_title: '',
                    cat_id: '',
                    ad_date: '',
                    ad_expiry: '',
                    ad_lng: '',
                    ad_lat: '',
                    ad_location: '',
                    relation_id: '',
                    ad_age_of_asset: '',
                }
            )
            setAddDetails('')
        }
    }, [status]);
    const SavePostJobForm = () => {
        dispatch(postJobForm(formData));
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileType = selectedFile.type;
            setFileType(fileType);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const uploadPostJobImage = () => {
        dispatch(uploadpostJobImage(
            {
                aimg_id: 0,
                ad_id: jobResponse?.ad_id,
                file: fileType
            }
        ));
    };

    console.log("Data in post job form", jobResponse)

    return (
        <section id="content_section">
            <div className="register_page">
                <div className="page_width">
                    <div className="gerenric_form">
                        {status != 'post_success' && (
                            <>
                                <div className="gerenric_heading">Create a Post</div>
                                <ul>
                                    <>
                                        <li>
                                            <div className="form_row">
                                                <div className="col_left">
                                                    <input type="text" name='ad_title' className="gerenric_input" placeholder="Post Title"
                                                        value={formData.ad_title}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col_right">
                                                    <select id=""
                                                        className="gerenric_input"
                                                        name='cat_id'
                                                        value={formData.cat_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option > Select Category</option>
                                                        {categorData && categorData.length > 0 && categorData.map((item, index) => {
                                                            return (
                                                                <option value={item?.cat_id} >
                                                                    {item?.cat_title}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form_row">
                                                <div className="col_left">
                                                    <input type="text" name='ad_location' className="gerenric_input" placeholder="Add Location"
                                                        value={formData.ad_location}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col_right">
                                                    <select
                                                        className="gerenric_input"
                                                        name='relation_id'
                                                        value={formData.relation_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option > Select Relation </option>
                                                        {relationData && relationData.length > 0 &&
                                                            relationData.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item?.relation_id} > {item?.relation_title} </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form_row">
                                                <div className="col_left">
                                                    <input type="text" name='ad_lat' className="gerenric_input" placeholder="Add Latitude"
                                                        value={formData.ad_lat}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col_right">
                                                    <input type="text" className="gerenric_input" placeholder="Post Langitude"
                                                        name='ad_lng'
                                                        value={formData.ad_lng}
                                                        onChange={handleInputChange}
                                                    /></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form_row">
                                                <div className="col_left">
                                                    <input type="date" name='ad_date' className="gerenric_input" placeholder="Add Date"
                                                        value={formData.ad_date}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col_right">
                                                    <input type="date" className="gerenric_input" placeholder="Post Expiry Date"
                                                        name='ad_expiry'
                                                        value={formData.ad_expiry}
                                                        onChange={handleInputChange}
                                                    /></div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="form_row">
                                                <div className="col_left">
                                                    <input type="text" name='ad_age_of_asset' className="gerenric_input" placeholder="Post Assets"
                                                        value={formData.ad_age_of_asset}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <ReactQuill theme="snow" value={addDetails} onChange={setAddDetails} />
                                        </li>

                                    </>
                                    <li>
                                        <button className="gerenric_button button_full" onClick={SavePostJobForm} >Post</button>
                                    </li>
                                </ul>
                            </>
                        )}

                        {status === 'post_success' && (
                            <>
                                <h2 className='gerenric_heading' >Please Add Images</h2>
                                <input type="file" className='gerenric_input' onChange={handleFileChange} />
                                {fileType && <p>Selected file type: {fileType}</p>}
                                {imagePreviewUrl && (
                                    <img src={imagePreviewUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                )}
                                <li>
                                    <button className="gerenric_button button_full" onClick={uploadPostJobImage} >Upload Image</button>
                                </li>
                            </>
                        )}



                    </div>
                </div>
            </div>
        </section>

    )
}

export default PostJob