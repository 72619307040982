import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import App from './App';
import './style/styles.scss';
import './style/responsive.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from 'react-router-dom';

import { Provider } from "react-redux";
import { store } from './redux/storeCreator'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <BrowserRouter basename="/demo" >
        <App />
      </BrowserRouter>
    </Provider>

  </React.StrictMode>
);

