import React, { useEffect } from 'react'
import Profile from '../../global/components/profile'
import useApi from '../../global/hooks/api'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { Link, useParams } from 'react-router-dom'

const ExpertProfile = () => {
    const { data, error, loading, getData, setEndpoint } = useApi();
    const dispatch = useDispatch()
    const param = useParams()

    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`user_profile&mem_uid=${param && param?.id}&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);
    const expert_details = data
    return (
        <div>
            <Profile details={expert_details} />
        </div>
    )
}

export default ExpertProfile