import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postClient } from "../../api/postClient";

// register form
export const registerUserForm = createAsyncThunk("authData/registerUserForm", async (params) => {
    const response = await postClient.post(`user_registration`, params);
    return response;
});

// login form
export const loginUserForm = createAsyncThunk("authData/loginUserForm", async (params) => {
    const response = await postClient.post(`user_login`, params);
    return response;
});

// Post Jon form
export const postJobForm = createAsyncThunk("authData/postJobForm", async (params) => {
    const response = await postClient.post(`post_job`, params);
    return response;
});

// Post Jon form
export const uploadpostJobImage = createAsyncThunk("authData/uploadpostJobImage", async (params) => {
    const response = await postClient.post(`upload_job_image`, params);
    return response;
});




const initialState = {
    registerData: null,
    loginData: null,
    postjobdata: null,
    status: "idle", // Added the 'status' property to initialState
    error: null, // Added the 'error' property to initialState
};

const registerSlice = createSlice({
    name: "registerUser",
    initialState,
    reducers: {
        updateStatus(state, action) {
            state.status = action.payload.status;
        },
    },
    extraReducers: (builder) => {
        // Sign Up / Register Form
        builder
            .addCase(registerUserForm.pending, (state) => {
                state.status = "sign_up_progress";
            })
            .addCase(registerUserForm.fulfilled, (state, action) => {
                if (action.payload.status === "1") {
                    state.status = "sign_up_success";
                    state.registerData = action.payload;
                } else {
                    state.status = "sign_up_error";
                    state.registerData = action.payload;
                }
            })
            .addCase(registerUserForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // login
            .addCase(loginUserForm.pending, (state) => {
                state.status = "login_progress";
            })
            .addCase(loginUserForm.fulfilled, (state, action) => {
                if (action.payload.status === "1") {
                    state.status = "login_success";
                    state.loginData = action.payload;
                } else {
                    state.status = "login_error";
                    state.loginData = action.payload;
                }
            })
            .addCase(loginUserForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })


            // post Job
            .addCase(postJobForm.pending, (state) => {
                state.status = "post_progress";
            })
            .addCase(postJobForm.fulfilled, (state, action) => {
                if (action.payload.status === "1") {
                    state.status = "post_success";
                    state.postjobdata = action.payload;
                }
            })
            .addCase(postJobForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            // post Job Image
            .addCase(uploadpostJobImage.pending, (state) => {
                state.status = "post_image_progress";
            })
            .addCase(uploadpostJobImage.fulfilled, (state, action) => {
                if (action.payload.status === "1") {
                    state.status = "post_image_success";
                    state.postjobdata = action.payload;
                }
            })
            .addCase(uploadpostJobImage.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { updateStatus } = registerSlice.actions;
const registerUserReducer = registerSlice.reducer;
export default registerUserReducer;

export const getRegisterUserSelector = (state) => state.registerUser.registerData; // Fixed the selector
export const getLoginUserSelector = (state) => state.registerUser.loginData; // Fixed the selector
export const getPostJobSelector = (state) => state.registerUser.postjobdata; // Fixed the selector
