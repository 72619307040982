import React, { useRef, useState, useEffect } from 'react'
import Image1 from '../../assets/images/categories_image1.jpg'
import Image2 from '../../assets/images/categories_image2.jpg'
import Image3 from '../../assets/images/categories_image3.jpg'
import Slider from 'react-slick'
import useApi from '../../global/hooks/api'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { Link, useParams } from 'react-router-dom'

const JobDetail = () => {
    const [sliderFor, setSliderFor] = useState(null);
    const [sliderNav, setSliderNav] = useState(null);

    const { data, error, loading, getData, setEndpoint } = useApi();
    const dispatch = useDispatch()
    const param = useParams()

    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_job_details&ad_id=${param && param?.id}&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);
    const job_details = data?.data[0]

    const sliderForSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: sliderNav
    };

    const sliderNavSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: sliderFor,
        dots: true,
        centerMode: true,
        focusOnSelect: true
    };
    return (
        <div>
            <section id="content_section">
                {loading ? 'Loading Data' :
                    <div className="job_detail_page gerenric_padding">
                        <div className="page_width">
                            <div className="job_detail_inner">
                                <div className="job_detail_left">
                                    <Slider ref={slider => setSliderFor(slider)} {...sliderForSettings} className="slider slider-content">
                                        <div><div className="detail_image"><img src={Image1} alt="" /></div></div>
                                        <div><div className="detail_image"><img src={Image2} alt="" /></div></div>
                                        <div><div className="detail_image"><img src={Image3} alt="" /></div></div>
                                    </Slider>
                                    <Slider ref={slider => setSliderNav(slider)} {...sliderNavSettings} className="slider slider-thumb">
                                        <div><div className="detail_image_thum"><img src={Image1} alt="" /></div></div>
                                        <div><div className="detail_image_thum"><img src={Image2} alt="" /></div></div>
                                        <div><div className="detail_image_thum"><img src={Image3} alt="" /></div></div>
                                    </Slider>
                                </div>
                                <div className="job_detail_right">
                                    <br />
                                    <div className="gerenric_heading "> {job_details?.ad_title} </div>
                                    <div className="job_detail_subtitle"> {job_details?.ad_location} </div>
                                    <ul>
                                        <li><span>Start Date :</span> {job_details?.ad_date} </li>
                                        <li><span>End Date :</span> {job_details?.ad_expiry}</li>
                                        <li><span>Job Status :</span> {job_details?.ad_status} <img src={job_details?.ad_status_icon} width={15} alt="" />  </li>
                                        <li>
                                            {job_details?.ad_details}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </div>
    )
}

export default JobDetail