postClient.getAuthHeaders = function () {
    return { "Content-Type": "application/json;charset=UTF-8", Authorization: "Basic " + btoa("fixxify:admin123") };
};

postClient.getAPIBaseUrl = function (endpoint) {
    let apiserver = "http://www.fixxify.nl/demo/api/index.php?action=";
    // let UserID = (window.user_id = window.user_id != undefined ? window.user_id : 1);
    // let UserRoleID = (window.urole_id = window.urole_id != undefined ? window.urole_id : 1);

    // if (window.location.href.includes("localhost:3000")) {
    //     // apiserver = "http://localhost:81/newco/api/index.php?action=";
    //     apiserver = "http://www.fixxify.nl/demo/api/index.php?action=";
    // } else {
    //     apiserver = "../api/index.php?action=";
    // }
    let url =
        apiserver +
        endpoint +
        "&random=" +
        Math.random().toString(36);

    return url;
};

export async function postClient(endpoint, { body, ...customConfig } = {}) {
    let baseUrl = postClient.getAPIBaseUrl(endpoint);
    let headers = postClient.getAuthHeaders();

    const config = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        //body.UserID=UserID;
        config.body = JSON.stringify(body);
    }

    let data;
    try {
        const response = await fetch(customConfig.useFullPath == true ? endpoint : baseUrl, config);
        data = await response.json();

        if (data.status == -1 || data.status == "-1") {
            window.location = "http://www.fixxify.nl/login";
            return;
        } else if (data.status == 1 || data.status == "1" || data.token != null || data) {
            return data;
        }
        throw new Error(response.message);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data);
    }
}

postClient.get = function (endpoint, customConfig = {}) {
    return postClient(endpoint, { ...customConfig, method: "GET" });
};

postClient.post = function (endpoint, body, customConfig = {}) {
    return postClient(endpoint, { ...customConfig, body });
};
