import React from 'react'
import Slider from 'react-slick';
import CategoryCard from '../../../global/components/categoryCard';
const PrepareWinter = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className="winter_section gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">PREPARE FOR WINTER</div>
                    <div className="gerenric_product full_column">
                        <div className="gerenric_slider">
                            <Slider {...settings}>
                                {Array.from({ length: 7 }).map((item, index) => {
                                    return (
                                        <CategoryCard
                                            title='Plastering'
                                            description="Would you like to know more about plastering, lowering ceilings or installing dividing walls?"
                                            key={index}
                                        />
                                    )
                                })}


                            </Slider>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrepareWinter