import React from 'react'
import { Link } from 'react-router-dom'
import CardImg from '../../assets/images/categories_image1.jpg'

const CategoryCard = ({ card_img, title, description, link }) => {
    return (
        <>
            <div className="pd_col">
                <div className="pd_item">
                    <div className="pd_image"><img src={card_img ? card_img : CardImg} alt="" /></div>
                    <div className="pd_detail">
                        <div className="pd_title"> {title ? `${title.slice(0, 20)}${title.length > 20 ? '...' : ''} ` : 'Please Write Title'} </div>
                        <p>{description ? description : 'Description'}</p>
                        <div className="full_width"><Link to={link ? `/category-listing/${link}` : '/category-listing/0'}><div className="gerenric_button">Read More</div></Link></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryCard