import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import useApi from '../../global/hooks/api';
import parse from 'html-react-parser';

const TermsCondition = () => {

    const { data, loading, error, setEndpoint, getData } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_contents&cnt_id=1&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const termsData = data && data?.data[0]

    return (
        <div>
            <section id="content_section">
                <div className="how_work_section gerenric_padding">
                    <div className="page_width">
                        {loading ? 'Loading Data' :
                            <>
                                <div className="gerenric_heading">{parse(termsData?.cnt_heading ? termsData?.cnt_heading : '')}</div>
                                <div className="privacy_data">
                                    {parse(termsData?.cnt_details ? termsData?.cnt_details : 'Please add Data')}
                                </div>
                            </>
                        }

                    </div>
                </div>

            </section>

        </div>
    )
}

export default TermsCondition