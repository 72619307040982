import React, { useEffect, useState } from 'react'
import LoginImage from '../../assets/images/login_bg.jpg'
import { Link } from 'react-router-dom'
import { loginUserForm, getLoginUserSelector } from '../../redux/slices/registerSlice'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { login } from '../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const selLang = useSelector(langSelector);
    const loginResponse = useSelector(getLoginUserSelector);
    const status = useSelector((state) => state.registerUser.status);
    const auth = useSelector((state) => state.auth);
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')


    useEffect(() => {
        if (status == 'login_success') {
            dispatch(login(loginResponse?.data))
            navigate('/user_profile')
        }
    }, [status])


    const SaveLoginForm = () => {
        if (password && userName) {
            dispatch(loginUserForm({
                user_name: userName,
                user_password: password
            }))
        }
    };

    console.log("data in response", auth)

    return (
        <section id="content_section">
            <div className="login_page register_page">
                <div className="page_width">
                    <div className="register_inner">
                        <div className="register_left"><img src={LoginImage} alt="" /></div>
                        <div className="register_right">
                            <div className="gerenric_form">
                                <div className="gerenric_heading">Login to your account?</div>
                                <ul>
                                    <li>
                                        {loginResponse != null && (
                                            <>
                                                <p>
                                                    {loginResponse?.message}
                                                </p>
                                            </>
                                        )}
                                    </li>


                                    <li><input type="email" className="gerenric_input" placeholder="EMAIL ADDRESS / USER NAME"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    /></li>
                                    <li><input type="password" className="gerenric_input" placeholder="PASSWORD"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    /></li>
                                    <li><button className="gerenric_button button_full" onClick={SaveLoginForm} >LOGIN</button></li>
                                    <li className="txt_align_center">Don't have an account? <Link to="/register">Register</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginPage