import React, { useEffect } from 'react'
import ListImage from '../../assets/images/winter_image2.jpg'
import YellowStar from '../../assets/images/yellow_star.svg'
import GreyStar from '../../assets/images/gray_star.svg'
import useApi from '../../global/hooks/api'
import { useSelector, useDispatch } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import { Link, useParams } from 'react-router-dom'

const CategoryListing = () => {

    const { data, error, loading, getData, setEndpoint } = useApi();
    const dispatch = useDispatch()
    const param = useParams()

    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_jobs&cat_id=${param && param?.id}&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);
    const categoryList = data?.data
    return (
        <section id="content_section">
            <div className="categroy_page gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">Jobs for Plumbing Services</div>
                    <div className="gerenric_category gerenric_product">
                        {loading ? "Loading Category" :
                            <>
                                {categoryList && categoryList.length > 0 ?
                                    <>
                                        {categoryList && categoryList.map((item, index) => {
                                            return (

                                                <div className="pd_col" key={index} >
                                                    <Link to={`/job_details/${item?.ad_id}`} >
                                                        <div className="pd_item">
                                                            <div className="pd_image"><img src={item?.ad_images.length ? item?.ad_images[0] : ListImage} alt="" /></div>
                                                            <div className="pd_detail">
                                                                <div className="pd_prise"> {item?.ad_title ? item?.ad_title : 'Jobs'} </div>
                                                                <div className="pd_title">{item?.ad_location ? item?.ad_location : 'Location'}</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>


                                            )
                                        })}
                                    </>
                                    :
                                    <p>Records Not Found</p>
                                }


                            </>
                        }


                    </div>
                </div>
            </div>
        </section>
    )
}

export default CategoryListing