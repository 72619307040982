import React, { useEffect } from 'react'
import Header from './header'
import Footer from './footer'
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage, langSelector } from "../../redux/slices/langSlice";
import useApi from '../hooks/api';

const Layout = ({ children }) => {

    const { data, loading, error, setEndpoint, getData } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_contents&cnt_id=2&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const webData = data && data?.data[0]

    return (
        <div>
            <Header webData={webData} />
            {children}
            <Footer webData={webData} />
        </div>
    )
}

export default Layout