import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./global/layout";
import PrivateRoute from "./global/components/privateRoute";
import { useSelector, useDispatch } from "react-redux";

// all pages goes here
import HomePage from "./pages/homePage";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import CategoryPage from "./pages/categoryPage";
import CategoryListing from "./pages/categoryListingPage";
import HowItWork from "./pages/howItWork";
import PrivacyPage from "./pages/privacyPage";
import TermsCondition from "./pages/termsCondition";
import JobDetail from "./pages/jobDetail";
import ExpertProfile from "./pages/expertProfile";
import AllExpert from "./pages/allExpert";
import PostJob from "./pages/postJob";
import UserProfile from "./pages/userProfile";

function App() {
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth?.isAuthenticated
  return (
    <>
      <div id="container" align="center">
        <Layout>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/login" exact element={<LoginPage />} />
            <Route path="/register" exact element={<RegisterPage />} />
            <Route path="/category" exact element={<CategoryPage />} />
            <Route path="/experts" exact element={<AllExpert />} />
            <Route path="/how-it-work" exact element={<HowItWork />} />
            <Route path="/privacy_policy" exact element={<PrivacyPage />} />
            <Route path="/terms_and_conditions" exact element={<TermsCondition />} />
            <Route path="/category-listing/:id" exact element={<CategoryListing />} />
            <Route path="/job_details/:id" exact element={<JobDetail />} />
            <Route path="/expert_details/:id" exact element={<ExpertProfile />} />
            <Route path="/post_job" element={<PrivateRoute element={<PostJob />} isAuthenticated={isAuthenticated} />} />
            <Route path="/user_profile" element={<PrivateRoute element={<UserProfile />} isAuthenticated={isAuthenticated} />} />
            {/* <PrivateRoute path="/post_job" element={<PostJob />} isAuthenticated={isAuthenticated} fallbackPath="/login" /> */}
          </Routes>
        </Layout>
      </div>
    </>
  );
}

export default App;
