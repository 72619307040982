import React, { useEffect } from 'react'
import Slider from 'react-slick';
import CategoryCard from '../../../global/components/categoryCard';

import { useSelector } from "react-redux";
import { langSelector } from "../../../redux/slices/langSlice";
import useApi from '../../../global/hooks/api'
const Category = () => {

    const { data, loading, error, setEndpoint, getData } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_home_categories&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const categoryData = data && data.data

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <div className="category_section gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">TOP CATEGORIES</div>
                    <div className="gerenric_product full_column">
                        <div className="gerenric_slider">
                            <Slider {...settings}>
                                {categoryData != null && categoryData.length > 0 && categoryData.map((item, index) => {
                                    return (
                                        <CategoryCard
                                            title={item?.cat_title}
                                            description={item?.cat_detail}
                                            card_img={item?.cat_image}
                                            link={item?.cat_id}
                                            key={index}
                                        />
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category