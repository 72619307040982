import { createSlice } from '@reduxjs/toolkit';
import detectBrowserLanguage from 'detect-browser-language';

const getInitialState = () => {
    const browserLang = detectBrowserLanguage();
    if (browserLang.includes('en')) {
        return { selectedLang: 1 };
    }
    else if (browserLang.includes('de')) {
        return { selectedLang: 2 };
    }
    return { selectedLang: 2 };
}


const langSlice = createSlice({
    name: 'language',
    initialState: getInitialState(),
    reducers: {
        changeLanguage(state, action) {
            state.selectedLang = action.payload;
        },
    },

})

export const { changeLanguage } = langSlice.actions;
export default langSlice.reducer;

export const langSelector = (state) => state.language.selectedLang;

