import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import langReducer from "./slices/langSlice";
import authReducer from './slices/authSlice';
import registerUserReducer from "./slices/registerSlice";

const persistConfig = {
    key: 'root',
    storage: storage,
    version: 1,
    blacklist: ['language', 'registerUser'],
};

const languagePersistConfig = { key: 'language', storage: storage, whitelist: ['selectedLang'] };


const rootReducer = combineReducers({
    language: persistReducer(languagePersistConfig, langReducer),
    auth: authReducer,
    registerUser: registerUserReducer
});


export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
});

export const persistor = persistStore(store);
