import React, { useEffect } from 'react'
import CategoryCard from '../../global/components/categoryCard'

import { useSelector } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";
import useApi from '../../global/hooks/api'

const CategoryPage = () => {

    const { data, loading, error, setEndpoint, getData } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_categories&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);

    const categoryData = data && data.data


    return (
        <section id="content_section">
            <div className="category_page gerenric_padding">
                <div className="page_width">
                    <div className="gerenric_heading">How does Werkspot work?</div>
                    <div className="gerenric_product four_column">
                        {loading ? "Loading Data" : <>
                            {categoryData != null && categoryData.length > 0 && categoryData.map((item, index) => {
                                return (
                                    <CategoryCard
                                        title={item?.cat_title}
                                        description={item?.cat_detail}
                                        card_img={item?.cat_image}
                                        link={item?.cat_id}
                                        key={index}
                                    />
                                )
                            })}
                        </>}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default CategoryPage