import React from 'react'
import Profile from '../../global/components/profile'

const UserProfile = () => {
    return (
        <div>
            <Profile
            // details={expert_details}
            />
        </div>
    )
}

export default UserProfile