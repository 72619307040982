import React, { useEffect } from 'react'
import useApi from '../../global/hooks/api'
import { useSelector } from "react-redux";
import { langSelector } from "../../redux/slices/langSlice";

import ExpertCard from '../../global/components/expertCard';

const AllExpert = () => {
    const { data, error, loading, getData, setEndpoint } = useApi();
    const selLang = useSelector(langSelector);
    useEffect(() => {
        setEndpoint(`get_experts&lang_id=${selLang == 'en' ? 1 : 2}`);
        getData();
    }, [selLang]);
    const experts = data?.data
    return (
        <div>
            <section id="content_section">
                <div className="category_page gerenric_padding">
                    <div className="page_width">
                        <div className="gerenric_heading">All Category Expert</div>
                        <div className="gerenric_product four_column">
                            {loading ? "Loading Data" : <>
                                {experts != null && experts.length > 0 && experts.map((item, index) => {
                                    return (
                                        <ExpertCard
                                            key={index}
                                            rating={item?.user_total_rating}
                                            name={`${item?.user_fname} ${item?.user_lname}`}
                                            desigi={item?.urole_name}
                                            id={item?.mem_uid}
                                        />
                                    )
                                })}
                            </>}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AllExpert